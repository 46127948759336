var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import './Header.scss';
import Tabs from './Tabs';
import TPSvgIcon from '../shared/components/svg-icon/TPSvgIcon';
import ReactGA from 'react-ga4';
import { Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import quizImg from '../images/quiz.png';
import Avatar from '@mui/material/Avatar';
import { fontSizeDynamic } from '../shared/components/fontsizeutils/fontSizeUtils';
var Header = function (props) {
    var headerTabs = props.headerTabs, portal = props.portal, setLogout = props.setLogout;
    var navigate = useNavigate();
    var _a = React.useState(null), anchorEl = _a[0], setAnchorEl = _a[1];
    var widthAndHeightForImages = window.innerWidth / 50;
    var handleClick = function (event) {
        setAnchorEl(event.currentTarget);
    };
    var handleClose = function () {
        setAnchorEl(null);
    };
    var open = Boolean(anchorEl);
    var handleLogout = function () {
        setLogout();
        sessionStorage.clear();
        navigate('/login');
    };
    var handleClickedTab = function (label) {
        ReactGA.event({
            category: "".concat(portal),
            action: 'Click',
            label: "".concat(portal, " ").concat(label, " tab"),
        });
    };
    var handleFaqClick = function () {
        navigate('/faqs');
    };
    var env = window._env_.REACT_APP_ENV || process.env.REACT_APP_ENV;
    return (_jsx(Box, __assign({ sx: { flexGrow: 1 }, id: 'header-box', "data-testid": 'header-box' }, { children: _jsx(AppBar, __assign({ position: 'static', className: 'headerMain' }, { children: _jsxs(Toolbar, __assign({ sx: { justifyContent: 'space-between' } }, { children: [_jsx(Box, __assign({ className: 'headerMain-logo' }, { children: _jsxs(IconButton, __assign({ sx: { mr: 2, display: 'flex', flexDirection: 'column' }, id: 'tandym-logo', "data-testid": 'tandym-logo' }, { children: [_jsx(TPSvgIcon, { resourceName: 'tandym-logo-icon' }), _jsx(Typography, __assign({ className: 'headerMain-env', sx: { ml: 'auto', fontSize: fontSizeDynamic(21) } }, { children: env === null || env === void 0 ? void 0 : env.toLocaleUpperCase() }))] })) })), _jsx(Box, { children: _jsx(Tabs, { headerTabs: headerTabs, tabSize: 'large', parentPath: 'header', tabClick: handleClickedTab, portal: portal }) }), _jsx(Box, { children: _jsxs(Box, __assign({ className: 'icon-align' }, { children: [_jsx(Link, __assign({ to: '/faqs' }, { children: _jsx(IconButton, __assign({ id: 'mail-icon', "data-testid": 'mail-icon', onClick: handleFaqClick }, { children: _jsx("img", { src: quizImg, alt: 'quiz-img', width: widthAndHeightForImages, height: widthAndHeightForImages }) })) })), _jsx(Avatar, __assign({ className: 'user-icon-avatar', sx: {
                                        fontSize: fontSizeDynamic(16),
                                        width: widthAndHeightForImages,
                                        height: widthAndHeightForImages,
                                    }, onClick: handleClick }, { children: sessionStorage.getItem('userInitials') })), _jsx(Popover, __assign({ disableScrollLock: true, className: 'logout-popover', open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    } }, { children: _jsx(Typography, __assign({ sx: { p: 2, fontSize: fontSizeDynamic(18) }, onClick: handleLogout }, { children: "Logout" })) }))] })) })] })) })) })));
};
export default Header;
