var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../../tandym-web-common/src/components/Header';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { useAppSelector, useAppDispatch } from '../store/hook';
import TPProgressModal from '../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import { setLogOut } from '../store/authSlice';
var RootLayout = function () {
    var location = useLocation();
    var _a = useState([]), tabs = _a[0], setTabs = _a[1];
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var dispatch = useAppDispatch();
    var _b = useState(false), loading = _b[0], setLoading = _b[1];
    var logoutHandler = function () {
        dispatch(setLogOut());
    };
    useEffect(function () {
        if (location.pathname.includes('/home')) {
            setLoading(true);
            setTimeout(function () {
                setLoading(false);
            }, 3000);
        }
        if (entitlementsData.entitlementDto) {
            setTabs([
                {
                    label: 'Home',
                    path: '/home',
                    show: true,
                },
                {
                    label: 'Profile',
                    path: '/profile/personal-details',
                    show: entitlementsData.entitlementDto.profile,
                },
                {
                    label: 'Placements',
                    path: '/placements/placements-details',
                    show: entitlementsData.entitlementDto.placement,
                },
                {
                    label: 'Time and Expenses',
                    path: '/timesheets-expenses',
                    show: entitlementsData.entitlementDto.timesheets,
                },
                {
                    label: 'Pay',
                    path: '/pay',
                    show: entitlementsData.entitlementDto.pay,
                },
                {
                    label: 'Learning',
                    path: '/learning/learning-pathways',
                    show: entitlementsData.entitlementDto.learning,
                },
                {
                    label: 'Info Center',
                    path: '/info-center/who-we-are',
                    show: entitlementsData.entitlementDto.infocenter,
                },
            ]);
        }
    }, [entitlementsData]);
    return (_jsxs(Box, { children: [location.pathname !== '/login' && (_jsx(Header, { portal: 'candidate', headerTabs: tabs, setLogout: logoutHandler })), _jsx(TPProgressModal, { isLoading: loading }), _jsx(Box, __assign({ component: 'main', sx: {
                    flexGrow: 1,
                    width: '100%',
                    position: 'absolute',
                    backgroundColor: '#F6F6F6',
                } }, { children: _jsx(Outlet, {}) }))] }));
};
export default RootLayout;
