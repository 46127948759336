var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import TPCustomModal from '../../../tandym-web-common/src/shared/components/modal/TPCustomModal';
import TPProgressModal from '../../../tandym-web-common/src/shared/components/modal/TPProgressModal';
import MyDocument from '../../../tandym-web-common/src/components/pdf/MyPDF';
import classes from './Home.module.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAPI } from '../../../tandym-web-common/src/shared/services/api/API';
import CandidateInfo from '../components/candidateinfo/CandidateInfo';
import CandidateUpdate from '../components/candidateupdate/CandidateUpdate';
import { TANDYM_CONSTANTS } from '../shared/Constants';
import { setLogIn, setLogOut } from '../store/authSlice';
import { useAppDispatch, useAppSelector } from '../store/hook';
import { setEntitlements } from '../store/entitlementSlice';
import { jwtDecode } from 'jwt-decode';
import { setTabNavigationGuard } from '../store/tabNavigationGuard';
import { setCollaborativeCoaching, setPII } from '../store/profileSlice';
import { fontSizeDynamic } from '../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var Home = function (props) {
    var _a, _b;
    var _c = useState(null), firstName = _c[0], setFirstName = _c[1];
    var _d = useState(null), lastName = _d[0], setLastName = _d[1];
    var validateURL = props.validateURL, acceptTermsURL = props.acceptTermsURL;
    var dispatch = useAppDispatch();
    var _e = useAppSelector(function (state) { return state.userAuth; }), authToken = _e.authToken, email = _e.email;
    var avtarNameValidation = function (firstName, lastName) {
        var firstNameValidation = (firstName === null || firstName === void 0 ? void 0 : firstName.trim().charAt(0)) || '';
        var lastNameValidation = (lastName === null || lastName === void 0 ? void 0 : lastName.trim().charAt(0)) || '';
        if (firstName && lastName) {
            return "".concat(firstNameValidation).concat(lastNameValidation);
        }
        else if (firstName) {
            return "".concat(firstNameValidation);
        }
        return '';
    };
    sessionStorage.setItem('userInitials', avtarNameValidation(firstName, lastName));
    useEffect(function () {
        var _a, _b;
        if (authToken && typeof authToken === 'string') {
            try {
                var decodedToken = jwtDecode(authToken);
                setFirstName((_a = decodedToken.FirstName) !== null && _a !== void 0 ? _a : null);
                setLastName((_b = decodedToken.LastName) !== null && _b !== void 0 ? _b : null);
            }
            catch (error) {
                console.error('Invalid token:', error);
            }
        }
        else {
            console.error('Invalid authToken:', authToken);
        }
    }, [authToken]);
    var entitlementsData = useAppSelector(function (state) { return state.entitlements; }).entitlementsData;
    var _f = useState(false), isLoading = _f[0], setIsLoading = _f[1];
    var _g = useState(false), checkbox = _g[0], setCheckbox = _g[1];
    var _h = useState(''), validateLink = _h[0], setValidateLink = _h[1];
    var _j = useState(false), isTermsAccepted = _j[0], setIsTermsAccepted = _j[1];
    var _k = useState(null), profileData = _k[0], setProfileData = _k[1];
    var hasFetched = useRef(false);
    var location = useLocation();
    var _l = useAPI(), httpGet = _l.httpGet, httpPost = _l.httpPost;
    var navigate = useNavigate();
    useEffect(function () {
        var params = new URLSearchParams(location.search);
        var tokenFromUrl = params.get('token') || '';
        if (tokenFromUrl && !hasFetched.current) {
            hasFetched.current = true;
            setValidateLink(tokenFromUrl);
            loginLinkValidation(tokenFromUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);
    useEffect(function () {
        if (authToken) {
            fetchCandidateInfo();
        }
    }, []);
    var loginLinkValidation = useCallback(function (loginLink) { return __awaiter(void 0, void 0, void 0, function () {
        var response, url, parsedUrl, cleanUrl, _a, Email, FirstName, LastName, error_1;
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 8, 9, 10]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(validateURL, {
                            code: loginLink,
                        })];
                case 1:
                    response = _c.sent();
                    if (!!response.error) return [3 /*break*/, 6];
                    url = (_b = window === null || window === void 0 ? void 0 : window.location) === null || _b === void 0 ? void 0 : _b.href;
                    parsedUrl = new URL(url);
                    parsedUrl.search = '';
                    cleanUrl = parsedUrl.toString();
                    window.history.pushState({}, '', cleanUrl);
                    if (!!response.latestTermsAccepted) return [3 /*break*/, 2];
                    setIsTermsAccepted(true);
                    return [3 /*break*/, 5];
                case 2:
                    _a = jwtDecode(response.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName;
                    return [4 /*yield*/, dispatch(setLogIn({
                            candidateId: response.mercuryContactId,
                            isLoggedIn: true,
                            email: Email,
                            authToken: response.authToken,
                            isEmailLinkExpired: false,
                            firstName: FirstName,
                            lastName: LastName,
                        }))];
                case 3:
                    _c.sent();
                    // Optionally store authToken in session storage
                    sessionStorage.setItem('authToken', response.authToken);
                    fetchEntitlements();
                    return [4 /*yield*/, fetchCandidateInfo()];
                case 4:
                    _c.sent();
                    _c.label = 5;
                case 5: return [3 /*break*/, 7];
                case 6:
                    dispatch(setLogOut());
                    sessionStorage.removeItem('authToken');
                    sessionStorage.removeItem('timeSheetEntitlements');
                    throw new Error(response.message || 'Login link is not valid');
                case 7: return [3 /*break*/, 10];
                case 8:
                    error_1 = _c.sent();
                    if (error_1.message === 'Login Code has expired') {
                        dispatch(setLogIn({
                            candidateId: '',
                            isLoggedIn: false,
                            email: email,
                            isEmailLinkExpired: true,
                        }));
                    }
                    navigate('/');
                    console.error('Login link validation error:', error_1);
                    hasFetched.current = false;
                    return [3 /*break*/, 10];
                case 9:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 10: return [2 /*return*/];
            }
        });
    }); }, [dispatch, email, navigate, validateURL]);
    var handleAcceptTerms = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, _a, Email, FirstName, LastName, error_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!checkbox) {
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, httpPost(acceptTermsURL, null, {
                            code: validateLink,
                        })];
                case 2:
                    response = _b.sent();
                    if (!response.error && response.latestTermsAccepted) {
                        setIsTermsAccepted(false);
                        _a = jwtDecode(response.authToken), Email = _a.Email, FirstName = _a.FirstName, LastName = _a.LastName;
                        dispatch(setLogIn({
                            candidateId: response.mercuryContactId,
                            isLoggedIn: true,
                            email: Email,
                            authToken: response.authToken,
                            firstName: FirstName,
                            lastName: LastName,
                        }));
                        sessionStorage.setItem('authToken', response.authToken);
                        setTimeout(function () {
                            fetchEntitlements();
                            fetchCandidateInfo();
                        }, 500);
                    }
                    else {
                        throw new Error(response.message || 'Login link is not valid');
                    }
                    return [3 /*break*/, 4];
                case 3:
                    error_2 = _b.sent();
                    console.error('Accept terms error:', error_2);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [checkbox, dispatch, email, httpPost, validateLink, acceptTermsURL]);
    var fetchCandidateInfo = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_PROFILE_GET)];
                case 1:
                    response = _c.sent();
                    if (response.code === 'TM_CANDIDATE_003' && !response.error) {
                        dispatch(setTabNavigationGuard({
                            mainTabNavigationGuard: false,
                        }));
                        dispatch(setCollaborativeCoaching({
                            isCollaborativeCoaching: (_a = response === null || response === void 0 ? void 0 : response.response) === null || _a === void 0 ? void 0 : _a.isCollaborativeCoaching,
                        }));
                        dispatch(setPII({
                            isPII: (_b = response === null || response === void 0 ? void 0 : response.response) === null || _b === void 0 ? void 0 : _b.isPII,
                        }));
                        setProfileData(response.response);
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_3 = _c.sent();
                    console.error('Fetch candidate info error:', error_3);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var fetchEntitlements = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, 3, 4]);
                    setIsLoading(true);
                    return [4 /*yield*/, httpGet(TANDYM_CONSTANTS.API_URLS.CANDIDATE_ENTITLEMENTS_GET)];
                case 1:
                    response = _a.sent();
                    if (!response.error) {
                        dispatch(setEntitlements({
                            entitlementsData: response,
                        }));
                        sessionStorage.setItem('timeSheetEntitlements', response.entitlementDto.timesheets);
                    }
                    else {
                        navigate('/');
                    }
                    return [3 /*break*/, 4];
                case 2:
                    error_4 = _a.sent();
                    console.error('Fetch entitlements error:', error_4);
                    return [3 /*break*/, 4];
                case 3:
                    setIsLoading(false);
                    return [7 /*endfinally*/];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    return (_jsxs(Box, __assign({ sx: { width: '100%', my: '2.15rem' }, id: 'home', "data-testid": 'home' }, { children: [_jsx(Box, __assign({ sx: { px: { xs: '0.625rem', md: '2.938rem' } } }, { children: _jsxs(Grid, __assign({ container: true, spacing: 2 }, { children: [_jsx(Grid, __assign({ sx: { display: { xs: 'none', sm: 'block' } }, item: true, xs: 0, sm: 5, md: 4, lg: 3, id: 'candidate-info-grid' }, { children: _jsx(CandidateInfo, { profileData: profileData, showUploadResume: ((_a = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _a === void 0 ? void 0 : _a.showUploadResume)
                                    ? (_b = entitlementsData === null || entitlementsData === void 0 ? void 0 : entitlementsData.entitlementDto) === null || _b === void 0 ? void 0 : _b.showUploadResume
                                    : false }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 7, md: 8, lg: 9, id: 'candidate-update-grid' }, { children: _jsx(CandidateUpdate, { profileData: profileData, entitlementsData: entitlementsData.callForActions }) }))] })) })), _jsxs(Box, { children: [_jsx(TPProgressModal, { isLoading: isLoading }), _jsx(TPCustomModal, { id: 'terms-and-conditions', "data-testid": 'terms-and-conditions', title: 'Terms and Conditions', PaperProps: { sx: { maxWidth: '50rem' } }, slotProps: { backdrop: { style: { backdropFilter: 'blur(5px)' } } }, DialogTitleProps: { sx: { p: '16px 45px' } }, cancelText: 'Cancel', confirmText: 'I Accept', open: isTermsAccepted, children: _jsx(MyDocument, { pdf: '../privacy-policy.pdf' }), DialogContentProps: { className: classes.scrollbar }, confirmButtonProps: { className: classes.confirmButton }, DialogActionsProps: {
                            className: classes.dialogAction,
                            sx: { m: '0px 10px 0px 32px', fontSize: fontSizeDynamic(21) },
                        }, checkboxLabel: 'I confirm that I have read and accept the terms and conditions and privacy policy.', onClose: function () {
                            setIsTermsAccepted(false);
                            navigate('/');
                        }, checkboxChecked: checkbox, onCheckboxChange: setCheckbox, onConfirm: handleAcceptTerms })] })] })));
};
export default Home;
