var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './MyPDF.scss';
pdfjs.GlobalWorkerOptions.workerSrc = "//unpkg.com/pdfjs-dist@".concat(pdfjs.version, "/build/pdf.worker.min.mjs");
function MyPDF(props) {
    var pdf = props.pdf;
    var _a = useState(), numPages = _a[0], setNumPages = _a[1];
    function onDocumentLoadSuccess(_a) {
        var numPages = _a.numPages;
        setNumPages(numPages);
    }
    var customTextRenderer = function (textItem) {
        return _jsx("span", __assign({ style: { color: 'blue' } }, { children: textItem.str }));
    };
    return (_jsx("div", __assign({ id: 'document', "data-testid": 'document' }, { children: _jsx(Document, __assign({ loading: 'Loading...', file: pdf, onLoadSuccess: onDocumentLoadSuccess }, { children: Array.from(new Array(numPages), function (_, index) { return (_jsx(Page, { pageNumber: index + 1, renderTextLayer: true, renderAnnotationLayer: true, devicePixelRatio: window.devicePixelRatio, scale: 1.2, width: 660, className: 'mypdf-page', customTextRenderer: customTextRenderer }, "page_".concat(index + 1))); }) })) })));
}
export default MyPDF;
