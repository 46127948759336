var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import TPButton from '../shared/components/button/TPButton';
import './Header.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector, } from '../../../tandym-web-candidate/src/store/hook';
import { 
// useClientAppDispatch, // for feature use
useClientAppSelector, } from '../../../tandym-web-client/src/store/hook';
import { setTabNavigationGuard, setNavigationGuardModalPopup, setNavigationHistory, } from '../../../tandym-web-candidate/src/store/tabNavigationGuard';
import { fontSizeDynamic } from '../shared/components/fontsizeutils/fontSizeUtils';
var Tabs = function (props) {
    var headerTabs = props.headerTabs, tabSize = props.tabSize, parentPath = props.parentPath, portal = props.portal, tabClick = props.tabClick;
    var location = useLocation();
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var mainTabNavigationGuard = (portal === 'client'
        ? useClientAppSelector(function (state) { return state.clientTabNavigationGuard; })
        : useAppSelector(function (state) { return state.tabNavigationGuard; })).mainTabNavigationGuard;
    var handleNavigate = function (path, label) {
        dispatch(setNavigationHistory({
            navigationHistory: path || '',
        }));
        if (mainTabNavigationGuard) {
            dispatch(setNavigationGuardModalPopup({
                navigationGuardModalPopup: true,
            }));
        }
        else {
            dispatch(setTabNavigationGuard({
                mainTabNavigationGuard: false,
            }), setNavigationGuardModalPopup({
                navigationGuardModalPopup: false,
            }));
            tabClick && tabClick(label);
            navigate(path !== null && path !== void 0 ? path : '');
        }
    };
    return (_jsx(_Fragment, { children: headerTabs.map(function (tab, index) {
            if (!tab.show)
                return null;
            return (_jsx(TPButton, __assign({ id: tab.label, "data-testid": tab.label, className: location.pathname === (tab.path || '') ||
                    location.pathname.includes(tab.path || '')
                    ? parentPath === 'header'
                        ? 'activeMainHeaderTab'
                        : 'activeHeaderTab'
                    : 'headerTab', onClick: function () { return handleNavigate((tab === null || tab === void 0 ? void 0 : tab.path) || '', (tab === null || tab === void 0 ? void 0 : tab.label) || ''); } }, { children: _jsx("p", __assign({ style: tabSize === 'small'
                        ? {
                            fontSize: fontSizeDynamic(16),
                            fontWeight: 500,
                            minWidth: 'auto',
                        }
                        : {
                            fontSize: fontSizeDynamic(20),
                            fontWeight: 500,
                            minWidth: 'auto',
                        } }, { children: tab.label })) }), index));
        }) }));
};
export default Tabs;
