var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Grid, Box } from '@mui/material';
import LoginForm from '../components/LoginForm';
import './Login.scss';
import TPTypography from '../shared/components/typography/TPTypography';
import TPSvgIcon from '../shared/components/svg-icon/TPSvgIcon';
import { fontSizeDynamic } from '../shared/components/fontsizeutils/fontSizeUtils';
var LoginPage = function (props) {
    var portal = props.portal, loginURL = props.loginURL, updateEmail = props.updateEmail, isExpired = props.isExpired;
    var windowWidth = window.innerWidth < 900;
    return (_jsxs(Grid, __assign({ container: true, id: 'login', "data-testid": 'login', justifyContent: 'center', mt: windowWidth ? window.innerWidth / 50 : 0 }, { children: [_jsxs(Grid, __assign({ item: true, xs: 12, md: 6, className: 'item1' +
                    ' ' +
                    (portal === 'candidate' ? 'candidateLogin' : 'clientLogin'), id: 'login-image', "data-testid": 'login-image' }, { children: [_jsx(Box, __assign({ id: 'tandym-icon', "data-testid": 'tandym-icon', sx: { position: 'absolute', mt: '6.188rem', ml: '1.563rem' } }, { children: _jsx(TPSvgIcon, { resourceName: 'tandym-text-icon' }) })), _jsxs(Box, __assign({ sx: {
                            position: 'absolute',
                            bottom: '9.063rem',
                            ml: '1.563rem',
                            zIndex: 1,
                        } }, { children: [_jsx(TPTypography, __assign({ className: 'imageText1', sx: { fontSize: fontSizeDynamic(40) } }, { children: "Connecting Talent with Opportunity" })), _jsx(TPTypography, __assign({ className: 'imageText2', sx: { fontSize: fontSizeDynamic(20) } }, { children: portal === 'candidate'
                                    ? 'Get placed with Tandym. Our platform connects professionals and job seekers to top opportunities. Join now and advance your career effortlessly!'
                                    : 'Here, you can efficiently manage your recruitment activities and monitor key metrics.' }))] }))] })), _jsx(Grid, __assign({ item: true, xs: 12, 
                // pt={windowWidth ? window.innerWidth / 60 : 0}
                md: 6, className: 'item2', id: 'login-form-grid', "data-testid": 'login-form-grid' }, { children: _jsx(Box, __assign({ id: 'login-form-box', "data-testid": 'login-form-box', width: '50%' }, { children: _jsx(LoginForm, { portal: portal, loginURL: loginURL, updateEmail: updateEmail, isExpired: isExpired, storedEmail: props.email }) })) }))] })));
};
export default LoginPage;
