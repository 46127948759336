var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from 'axios';
import { useState, useCallback } from 'react';
var axiosInstance = axios.create({
    baseURL: window._env_.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});
axiosInstance.interceptors.response.use(function (response) {
    if (response && response.status === 401) {
        sessionStorage.clear();
        window.location.href = '/'; // Redirect to login page when token is expired
    }
    return response;
});
var getRequestConfig = function (url, params, isSpinner, isMultipart) {
    if (isSpinner === void 0) { isSpinner = true; }
    if (isMultipart === void 0) { isMultipart = false; }
    var authToken = sessionStorage.getItem('authToken');
    if (!authToken &&
        !url.includes('/GenerateLoginLink') &&
        !url.includes('/ValidateLoginLink') &&
        !url.includes('/GenerateClientLoginLink') &&
        !url.includes('/ValidateClientLoginLink') &&
        !url.includes('/AcceptTerms')) {
        window.location.href = '/'; // Prevent unauthorized requests
    }
    // Determine if the authToken should be included based on the URL
    var shouldIncludeAuthToken = authToken &&
        !url.includes('/GenerateLoginLink') &&
        !url.includes('/ValidateLoginLink') &&
        !url.includes('/GenerateClientLoginLink') &&
        !url.includes('/ValidateClientLoginLink') &&
        !url.includes('/AcceptTerms');
    return {
        validateStatus: function () { return true; },
        params: params,
        headers: __assign({ 'Content-Type': isMultipart ? 'multipart/form-data' : 'application/json', isLoading: isSpinner ? 'BackDrop' : null }, (shouldIncludeAuthToken && { Authorization: "Bearer ".concat(authToken) })),
    };
};
export var useAPI = function () {
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var httpGet = useCallback(function (url, params, isSpinner, cancelToken // Accepting AbortSignal to handle cancellations
    ) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axiosInstance.get(url, __assign(__assign({}, getRequestConfig(url, params, isSpinner)), { signal: cancelToken }))];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_1 = _a.sent();
                    if (axios.isCancel(error_1)) {
                        console.warn('Request canceled:', error_1.message);
                    }
                    else {
                        console.error('GET request error:', error_1);
                    }
                    return [2 /*return*/, Promise.reject(error_1)];
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    var httpPost = useCallback(function (url, data, params, isSpinner, isMultipart) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axiosInstance.post(url, data, getRequestConfig(url, params, isSpinner, isMultipart))];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_2 = _a.sent();
                    console.error('POST request error:', error_2);
                    throw error_2;
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    var httpPut = useCallback(function (url, data, params, isSpinner, isMultipart) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, axiosInstance.put(url, data, getRequestConfig(url, params, isSpinner, isMultipart))];
                case 2:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 3:
                    error_3 = _a.sent();
                    console.error('PUT request error:', error_3);
                    throw error_3;
                case 4:
                    setLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); }, []);
    return {
        httpGet: httpGet,
        httpPost: httpPost,
        httpPut: httpPut,
        loading: loading,
    };
};
