export var TANDYM_CONSTANTS = {
    API_URLS: {
        LOGIN_LINK_VALIDATION_GET: '/Auth/ValidateLoginLink',
        GENERATE_LOGIN_LINK: '/Auth/GenerateLoginLink',
        ACCEPT_TERMS_POST: '/Auth/AcceptTerms',
        NAJAXA_API: '/Najaxa/GenerateNajaxaToken',
        CANDIDATE_PROFILE_GET: '/Candidate/GetCandidateProfileById',
        CANDIDATE_PROFILE_UPDATE: '/Candidate/UpdateCandidateProfile',
        CANDIDATE_PLACEMENT_GET: '/Candidate/GetPlacementById',
        CANDIDATE_PLACEMENT_DROPDOWN: '/Candidate/GetAllPlacementsByCandidateId',
        CANDIDATE_CONTACT_PREFERENCE_GET: '/Candidate/GetContactPreferencesById',
        CANDIDATE_CONTACT_PREFERENCE_UPDATE: '/Candidate/UpdateCandidateContactPreferences',
        CANDIDATE_PLACEMENT_BYID_GET: '/Candidate/GetComplianceById',
        CANDIDATE_RESUME_UPLOAD_POST: '/Candidate/UploadResume',
        CANDIDATE_COMPLIANCE_UPLOAD_EVIDENCE_POST: '/Candidate/UploadEvidence',
        CANDIDATE_ADD_SKILL: '/Candidate/UpdateCandidateSkills',
        CANDIDATE_TYPE_HEAD_SKILLS_GET: '/Candidate/typeahead',
        CANDIDATE_ENTITLEMENTS_GET: '/Candidate/GetEntitlementsForCandidate',
        CANDIDATE_COLLABORATIVE_COACHING_ACCEPT_TERMS_POST: '/Candidate/CollaborativeCoachingAcceptTerms',
        CANDIDATE_DOWNLOAD_EVIDENCE_POST: '/Candidate/DownloadCompliance',
        CANDIDATE_RESUME_DOWNLOAD_GET: '/Candidate/DownloadResume',
    },
};
