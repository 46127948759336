var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Box, FormControlLabel, Checkbox, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import classes from './TPCustomModal.module.css';
import classNames from 'classnames';
import TPButton from '../button/TPButton';
import { fontSizeDynamic } from '../fontsizeutils/fontSizeUtils';
import handleSmallMediumLargeSize from '../../utilities/size';
var theme = createTheme({
// ... your theme customizations (if any)
});
var CustomModal = function (_a) {
    var open = _a.open, title = _a.title, onClose = _a.onClose, onConfirm = _a.onConfirm, confirmText = _a.confirmText, cancelText = _a.cancelText, children = _a.children, _b = _a.PaperProps, PaperProps = _b === void 0 ? {} : _b, _c = _a.DialogContentProps, DialogContentProps = _c === void 0 ? {} : _c, _d = _a.DialogTitleProps, DialogTitleProps = _d === void 0 ? {} : _d, _e = _a.confirmButtonProps, confirmButtonProps = _e === void 0 ? {} : _e, _f = _a.cancelButtonProps, cancelButtonProps = _f === void 0 ? {} : _f, _g = _a.DialogActionsProps, DialogActionsProps = _g === void 0 ? {} : _g, checkboxLabel = _a.checkboxLabel, _h = _a.checkboxChecked, checkboxChecked = _h === void 0 ? false : _h, onCheckboxChange = _a.onCheckboxChange, id = _a.id, _j = _a.slotProps, slotProps = _j === void 0 ? {} : _j, props = __rest(_a, ["open", "title", "onClose", "onConfirm", "confirmText", "cancelText", "children", "PaperProps", "DialogContentProps", "DialogTitleProps", "confirmButtonProps", "cancelButtonProps", "DialogActionsProps", "checkboxLabel", "checkboxChecked", "onCheckboxChange", "id", "slotProps"]);
    return (_jsx(ThemeProvider, __assign({ theme: theme }, { children: _jsxs(Dialog, __assign({ onClose: onClose, open: open, slotProps: __assign({}, slotProps), PaperProps: __assign({ sx: { width: '100%', maxWidth: '60rem' } }, PaperProps), id: id, "data-testid": id }, props, { children: [title && (_jsx(DialogTitle, __assign({ id: id + 'title', "data-testid": id + 'title', className: classes.dialogTitle }, DialogTitleProps, { children: title }))), _jsx(DialogContent, __assign({}, DialogContentProps, { children: children })), _jsxs(DialogActions, __assign({ sx: __assign({}, DialogActionsProps === null || DialogActionsProps === void 0 ? void 0 : DialogActionsProps.sx) }, DialogActionsProps, { children: [checkboxLabel && (_jsx(Box, __assign({ sx: { display: 'flex', alignItems: 'center' } }, { children: _jsx(FormControlLabel, { sx: {
                                    '& .MuiTypography-root': {
                                        fontSize: "".concat((window.innerWidth / 1920) * (17 / 16), "rem"),
                                    },
                                }, control: _jsx(Checkbox, { id: id + '-checkbox', "data-testid": id + '-checkbox', size: handleSmallMediumLargeSize(window.innerWidth), checked: checkboxChecked, onChange: function (e) { return onCheckboxChange === null || onCheckboxChange === void 0 ? void 0 : onCheckboxChange(e.target.checked); } }), componentsProps: {
                                    typography: {
                                        sx: { fontSize: fontSizeDynamic(16) },
                                    },
                                }, label: checkboxLabel }) }))), _jsxs(Box, __assign({ sx: { display: 'flex', justifyContent: 'flex-end' } }, { children: [cancelText && (_jsx(TPButton, __assign({ onClick: onClose, id: id + '-cancel-btn', "data-testid": id + '-cancel-btn', className: classNames(classes.cancelButton, cancelButtonProps.className), color: 'secondary', sx: { fontSize: fontSizeDynamic(14) } }, { children: cancelText }))), confirmText && (_jsx(TPButton, __assign({ onClick: onConfirm, disabled: !checkboxChecked, variant: 'contained', id: id + '-confirm-btn', "data-testid": id + '-confirm-btn', className: classNames(classes.confirmButton, confirmButtonProps.className), color: 'primary', sx: { fontSize: fontSizeDynamic(14) } }, { children: confirmText })))] }))] }))] })) })));
};
export default CustomModal;
