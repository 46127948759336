export var EntitlementsData = {
    callForActions: [
        {
            actionId: '',
            name: 'TimesheetSubmission',
            title: 'Submit Time and Expenses',
            description: 'To ensure accurate tracking of your work and expenses, please submit your time logs and expense reports according to the guidelines below.',
            actionButtonName: 'Submit',
            externalUrl: '',
            internalPageId: 'https://candidate.dv.tandymgroup.com/timesheets-expenses',
            isExternal: false,
            candidateType: 'active',
            sequenceNumber: '1',
            effectiveStartDate: '',
            endDate: '',
            controlFlag: 'none',
            controlFunctionName: 'none',
            showUploadResume: false,
        },
    ],
    entitlementDto: {
        profile: true,
        timesheets: false,
        placement: true,
        learning: false,
        isolved: false,
        pay: false,
        showDownload: true,
        infocenter: true,
        profileJson: {
            personalDetails: true,
            skills: true,
            summary: true,
            preference: true,
        },
        candidateInfoCenterJson: {
            aboutTandym: true,
            isolved: true,
            complianceTraining: true,
            sickTime: true,
            timeReportingProcedures: true,
            fieldStaffEmployeeHandbook: true,
            referralBonusGuideline: true,
            employeeVerification: true,
            pointsofContact: true,
            learning: true,
            onBoarding: false,
            contactBenefits: true,
            iSolvedPeopleCloudGuide: true,
        },
        learningJson: {
            coachingTHButton: true,
            coachingTabpagebeforeclickTH: true,
            freeLearningResources: true,
        },
        fAQBenefitsAndInsurance: false,
        showPayDetails: true,
    },
};
