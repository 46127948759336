var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Box } from '@mui/material';
import ComplianceTraining from '../../../../tandym-web-common/src/images/Compliance-Training.png';
import ComplianceTraining1 from '../../../../tandym-web-common/src/images/Compliance-Training1.png';
import ComplianceTraining2 from '../../../../tandym-web-common/src/images/Compliance-Training2.png';
import PointsOfContact from '../../../../tandym-web-common/src/images/Points-of-contact.png';
import timeReportingBanner from '../../../../tandym-web-common/src/images/time-reporting-procedures-banner.png';
import learningBanner from '../../../../tandym-web-common/src/images/tandym-learning-resource-center.png';
import whoWeAreImage from '../../../../tandym-web-common/src/images/who-we-are-image.png';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var TypographyFontStyle = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 400;\n"], ["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 400;\n"])), fontSizeDynamic(32));
var TypographyH6Style = css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 300;\n  margin-top: 19.2px;\n"], ["\n  font-size: ", ";\n  color: #ffffff;\n  font-weight: 300;\n  margin-top: 19.2px;\n"])), fontSizeDynamic(18));
var TypographyH2Style = css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: 400;\n"], ["\n  font-size: ", ";\n  font-weight: 400;\n"])), fontSizeDynamic(20));
var TypographyFsStyle = css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: ", ";\n  font-weight: 300;\n"], ["\n  font-size: ", ";\n  font-weight: 300;\n"])), fontSizeDynamic(16));
var AtionBtnStyle = css(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 9.6px;\n  margin-top: auto;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 9.6px;\n  margin-top: auto;\n"])));
var Width44Style = css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 44%;\n"], ["\n  width: 44%;\n"])));
var ColorGrayStyle = css(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #131416;\n"], ["\n  color: #131416;\n"])));
var ColorGrayLightStyle = css(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  color: #404040;\n"], ["\n  color: #404040;\n"])));
var BoxBgStyle = css(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  border: 1px solid #ececec;\n  background-color: #fafafa;\n"], ["\n  border: 1px solid #ececec;\n  background-color: #fafafa;\n"])));
export var InfoCenterStyle = styled(Box)(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  .info-center {\n    & .sub-routes-header {\n      display: inline-block;\n    }\n    & .faq-link-section {\n      float: right;\n    }\n    & .need-help {\n      font-size: ", ";\n      font-weight: 400;\n      color: #000000;\n    }\n  }\n  .text-decoration-none {\n    text-decoration: none;\n  }\n"], ["\n  .info-center {\n    & .sub-routes-header {\n      display: inline-block;\n    }\n    & .faq-link-section {\n      float: right;\n    }\n    & .need-help {\n      font-size: ", ";\n      font-weight: 400;\n      color: #000000;\n    }\n  }\n  .text-decoration-none {\n    text-decoration: none;\n  }\n"])), fontSizeDynamic(14));
export var TrainingStyle = styled(Box)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  .compliance-training .img-1 {\n    padding-top: 90px;\n    padding-bottom: 100px;\n    background-image: url('", "');\n    & .MuiTypography-h1 {\n      ", "\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"], ["\n  .compliance-training .img-1 {\n    padding-top: 90px;\n    padding-bottom: 100px;\n    background-image: url('", "');\n    & .MuiTypography-h1 {\n      ", "\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"])), ComplianceTraining, TypographyFontStyle, TypographyH6Style, Width44Style);
export var TrainingBannerStyle = styled(Box)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  .img-2 {\n    background-image: url('", "');\n  }\n  .img-3 {\n    background-image: url('", "');\n  }\n  .img-2,\n  .img-3 {\n    padding-left: 20px;\n    padding-top: 150px;\n    padding-bottom: 50px;\n    border-bottom: #ececec 1px solid;\n    .MuiTypography-h2 {\n      ", "\n      ", "\n      margin-bottom: 9.6px;\n      margin-top: 20.8px;\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"], ["\n  .img-2 {\n    background-image: url('", "');\n  }\n  .img-3 {\n    background-image: url('", "');\n  }\n  .img-2,\n  .img-3 {\n    padding-left: 20px;\n    padding-top: 150px;\n    padding-bottom: 50px;\n    border-bottom: #ececec 1px solid;\n    .MuiTypography-h2 {\n      ", "\n      ", "\n      margin-bottom: 9.6px;\n      margin-top: 20.8px;\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"])), ComplianceTraining1, ComplianceTraining2, ColorGrayStyle, TypographyH2Style, ColorGrayLightStyle, TypographyFsStyle);
export var TimeReportingBannerStyle = styled(Box)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  .img-1 {\n    padding-top: 50px;\n    padding-bottom: 80px;\n    background-image: url('", "');\n    & .MuiTypography-h1 {\n      ", "\n    }\n    & .MuiTypography-h2 {\n      ", "\n      ", "\n    }\n  }\n"], ["\n  .img-1 {\n    padding-top: 50px;\n    padding-bottom: 80px;\n    background-image: url('", "');\n    & .MuiTypography-h1 {\n      ", "\n    }\n    & .MuiTypography-h2 {\n      ", "\n      ", "\n    }\n  }\n"])), timeReportingBanner, TypographyFontStyle, Width44Style, TypographyH6Style);
export var TimeReportingBoxStyle = styled(Box)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  ", "\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 27.2px 18.4px 20.8px 18.4px;\n  & .MuiTypography-h2 {\n    ", "\n    ", "\n  }\n  & .MuiTypography-h6 {\n    ", "\n    ", "\n    margin-top: 19.2px;\n    margin-bottom: 27.2px;\n  }\n  & .action-btns {\n    ", "\n  }\n"], ["\n  ", "\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  padding: 27.2px 18.4px 20.8px 18.4px;\n  & .MuiTypography-h2 {\n    ", "\n    ", "\n  }\n  & .MuiTypography-h6 {\n    ", "\n    ", "\n    margin-top: 19.2px;\n    margin-bottom: 27.2px;\n  }\n  & .action-btns {\n    ", "\n  }\n"])), BoxBgStyle, ColorGrayStyle, TypographyH2Style, ColorGrayLightStyle, TypographyFsStyle, AtionBtnStyle);
export var ResourcesPoliciesStyle = styled(Box)(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  article.MuiBox-root {\n    ", "\n    display: flex;\n    flex-direction: column;\n    padding: 27.2px 18.4px 20.8px 18.4px;\n    & .MuiTypography-h2 {\n      ", "\n      ", "\n    }\n    & .MuiTypography-h3 {\n      ", "\n      ", "\n      margin-top: 1rem;\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n      margin-top: 12px;\n      margin-bottom: 27.2px;\n    }\n    & .action-btns {\n      ", "\n    }\n  }\n"], ["\n  article.MuiBox-root {\n    ", "\n    display: flex;\n    flex-direction: column;\n    padding: 27.2px 18.4px 20.8px 18.4px;\n    & .MuiTypography-h2 {\n      ", "\n      ", "\n    }\n    & .MuiTypography-h3 {\n      ", "\n      ", "\n      margin-top: 1rem;\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n      margin-top: 12px;\n      margin-bottom: 27.2px;\n    }\n    & .action-btns {\n      ", "\n    }\n  }\n"])), BoxBgStyle, ColorGrayStyle, TypographyH2Style, ColorGrayStyle, TypographyH2Style, ColorGrayLightStyle, TypographyFsStyle, AtionBtnStyle);
export var LearningBannerStyle = styled(Box)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  .MuiBox-root.img-1 {\n    padding-top: 150px;\n    padding-bottom: 235px;\n    background-image: url('", "');\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h3 {\n      ", "\n      font-size: ", ";\n      margin-top: 9.6px;\n      font-weight: 500;\n      font-style: italic;\n      color: #ffffff;\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"], ["\n  .MuiBox-root.img-1 {\n    padding-top: 150px;\n    padding-bottom: 235px;\n    background-image: url('", "');\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h3 {\n      ", "\n      font-size: ", ";\n      margin-top: 9.6px;\n      font-weight: 500;\n      font-style: italic;\n      color: #ffffff;\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"])), learningBanner, TypographyFontStyle, Width44Style, fontSizeDynamic(18), Width44Style, TypographyH6Style);
export var ContactBannerStyle = styled(Box)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  .img-1 {\n    padding-top: 50px;\n    padding-bottom: 60px;\n    background-image: url('", "');\n    & .MuiTypography-h2 {\n      ", "\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"], ["\n  .img-1 {\n    padding-top: 50px;\n    padding-bottom: 60px;\n    background-image: url('", "');\n    & .MuiTypography-h2 {\n      ", "\n    }\n    & .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n  }\n"])), PointsOfContact, TypographyFontStyle, Width44Style, TypographyH6Style);
export var ContactBoxStyle = styled(Box)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  ", "\n  flex-grow: 1;\n  padding: 27.2px 18.4px;\n  & .MuiTypography-h3 {\n    ", "\n    ", "\n  }\n  & .MuiTypography-h4 a {\n    color: #2a70ea;\n    font-size: ", ";\n    font-weight: 600;\n    display: inline-block;\n    text-decoration: none;\n    margin-top: auto;\n    border-bottom: 1px solid #2a70ea;\n  }\n"], ["\n  ", "\n  flex-grow: 1;\n  padding: 27.2px 18.4px;\n  & .MuiTypography-h3 {\n    ", "\n    ", "\n  }\n  & .MuiTypography-h4 a {\n    color: #2a70ea;\n    font-size: ", ";\n    font-weight: 600;\n    display: inline-block;\n    text-decoration: none;\n    margin-top: auto;\n    border-bottom: 1px solid #2a70ea;\n  }\n"])), BoxBgStyle, ColorGrayStyle, TypographyH2Style, fontSizeDynamic(18));
export var CaliforniaHeading = styled(Box)(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #131416;\n  font-weight: 400;\n  border-top: 2px solid #ececec;\n  margin-top: 1.7rem;\n  padding-top: 1.7rem;\n"], ["\n  font-size: ", ";\n  color: #131416;\n  font-weight: 400;\n  border-top: 2px solid #ececec;\n  margin-top: 1.7rem;\n  padding-top: 1.7rem;\n"])), fontSizeDynamic(20));
export var WhoWeAreStyle = styled(Box)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  .MuiBox-root.img-1 {\n    padding-top: 130px;\n    padding-bottom: 230px;\n    background-image: url('", "');\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"], ["\n  .MuiBox-root.img-1 {\n    padding-top: 130px;\n    padding-bottom: 230px;\n    background-image: url('", "');\n    .MuiTypography-h2 {\n      color: #ffffff;\n      ", "\n    }\n    .MuiTypography-h6 {\n      ", "\n      ", "\n    }\n    .action-btns {\n      margin-top: 48px;\n    }\n  }\n"])), whoWeAreImage, TypographyFontStyle, Width44Style, TypographyH6Style);
export var StateSupplementStyle = styled(Box)(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  font-size: ", ";\n  color: #131416;\n  font-weight: 400;\n  border-top: 2px solid #ececec;\n  margin-top: 1.7rem;\n  padding-top: 1.7rem;\n"], ["\n  font-size: ", ";\n  color: #131416;\n  font-weight: 400;\n  border-top: 2px solid #ececec;\n  margin-top: 1.7rem;\n  padding-top: 1.7rem;\n"])), fontSizeDynamic(20));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
