var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useFaqContext } from './Faqs';
import add_icon_normal from '../../images/add_icon_normal.png';
import minus_icon_normal from '../../images/minus_icon-normal.png';
import { useLocation } from 'react-router-dom';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
import { Box } from '@mui/material';
var FaqQuestion = function (_a) {
    var question = _a.question, answer = _a.answer, subAnswer = _a.subAnswer, bullets = _a.bullets, orderList = _a.orderList;
    var isVisible = useFaqContext().isVisible;
    var _b = useState(false), isOpen = _b[0], setIsOpen = _b[1];
    var location = useLocation();
    var faqsKey = 'faqs';
    var widthForImages = window.innerWidth / 95;
    useEffect(function () {
        setIsOpen(isVisible);
    }, [isVisible]);
    var toggleAccordion = function () {
        setIsOpen(!isOpen);
    };
    useEffect(function () {
        setIsOpen(false);
    }, [location]);
    return (_jsxs(Box, __assign({ className: 'faq-row', p: 2 }, { children: [_jsxs(Box, __assign({ style: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }, id: 'faq-question', onClick: toggleAccordion }, { children: [_jsx(Box, __assign({ fontSize: fontSizeDynamic(20) }, { children: question ? question : null })), _jsx(Box, __assign({ className: 'faq-expand-icon' }, { children: isOpen ? (_jsx("img", { src: minus_icon_normal, alt: 'Minus icon', width: widthForImages, height: widthForImages })) : (_jsx("img", { src: add_icon_normal, alt: 'Add icon', width: widthForImages, height: widthForImages })) }))] })), isOpen ? (_jsxs("div", __assign({ className: 'faq-answer', style: { fontSize: fontSizeDynamic(16) } }, { children: [answer && _jsx("p", __assign({ id: 'faq-answer' }, { children: answer })), subAnswer && _jsx("p", __assign({ id: 'faq-sub-answer' }, { children: subAnswer })), orderList && (_jsx("ol", __assign({ id: 'faq-order-list' }, { children: orderList === null || orderList === void 0 ? void 0 : orderList.map(function (item) {
                            var _a;
                            return (_jsxs("li", { children: [item.text, _jsx("ul", __assign({ id: 'faq-sub-order-list' }, { children: (_a = item.subList) === null || _a === void 0 ? void 0 : _a.map(function (subItem) { return (_jsx("li", { children: subItem.text }, subItem.id)); }) }))] }, faqsKey + item.id));
                        }) }))), bullets && (_jsx("ul", { children: bullets === null || bullets === void 0 ? void 0 : bullets.map(function (bullet, index) { return (_jsx("li", { children: bullet }, faqsKey + index)); }) }))] }))) : null] })));
};
export default FaqQuestion;
