var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import FaqQuestion from './FaqQuestion';
import { useLocation } from 'react-router-dom';
import { Throttle } from '../../utilities/Throttle';
import { useEffect } from 'react';
var FAQComponent = function (props) {
    var faqs = props.faqs;
    var location = useLocation();
    var faqsKey = 'faqs';
    useEffect(function () {
        var handlePopState = Throttle(function (event) {
            event.preventDefault();
            if (location.pathname === '/faqs/sick-time') {
                window.history.go(-2);
            }
        }, 1000);
        window.addEventListener('popstate', handlePopState);
        return function () {
            window.removeEventListener('popstate', handlePopState);
        };
    }, [location]);
    return (_jsx("div", { children: faqs.map(function (faq) { return (_jsx(FaqQuestion, __assign({}, faq), faqsKey + faq.id)); }) }));
};
export default FAQComponent;
