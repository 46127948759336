var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import './CandidateUpdate.scss';
import TPButton from '../../../../tandym-web-common/src/shared/components/button/TPButton';
import TPCard from '../../../../tandym-web-common/src/shared/components/card/TPCard';
import { useNavigate } from 'react-router-dom';
import TPSvgIcon from '../../../../tandym-web-common/src/shared/components/svg-icon/TPSvgIcon';
import ReactGA from 'react-ga4';
import { fontSizeDynamic } from '../../../../tandym-web-common/src/shared/components/fontsizeutils/fontSizeUtils';
var CandidateUpdate = function (props) {
    var _a, _b;
    var profileData = props.profileData, entitlementsData = props.entitlementsData;
    var navigate = useNavigate();
    var navigationHandler = function (path, actionTitle, externalUrl) {
        if (externalUrl && externalUrl !== 'none') {
            window.open(externalUrl, '_blank', 'width=1000,height=700,top=20,left=20');
        }
        else {
            var url = new URL(path);
            var updatedPath = url.pathname;
            ReactGA.event({
                category: 'Candidate',
                action: 'Click',
                label: "".concat(actionTitle, " action button"),
            });
            navigate(updatedPath);
        }
    };
    return (_jsxs(Box, __assign({ id: 'candidate-update', "data-testid": 'candidate-update' }, { children: [_jsxs(Box, __assign({ className: 'candidate-update-profile' }, { children: [_jsxs(Typography, __assign({ className: 'candidate-update-name', id: 'candidate-update-name', "data-testid": 'candidate-update-name', fontSize: fontSizeDynamic(32) }, { children: ["Welcome ", (_a = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _a === void 0 ? void 0 : _a.firstName, ' ', (_b = profileData === null || profileData === void 0 ? void 0 : profileData.generalDetails) === null || _b === void 0 ? void 0 : _b.lastName, ","] })), _jsx(Typography, __assign({ className: 'candidate-update-info', id: 'candidate-update-info', "data-testid": 'candidate-update-info', fontSize: fontSizeDynamic(16) }, { children: "Stay connected, track progress, and grow with tailored resources just for you." })), _jsx(TPButton, { children: 'Update My Profile', color: 'primary', id: 'update-profile-button', "data-testid": 'update-profile-button', className: 'update-profile-button', sx: { fontSize: fontSizeDynamic(18) }, onClick: function () { return navigate('/profile/personal-details'); } })] })), _jsx(Grid, __assign({ container: true, spacing: 2, className: 'candidate-content', sx: { mt: '22px' } }, { children: entitlementsData && entitlementsData.length > 0 ? (entitlementsData.map(function (action, index) {
                    return (_jsx(Grid, __assign({ item: true, xs: 12, sm: 6 }, { children: _jsxs(TPCard, __assign({ customStyles: {
                                display: 'flex',
                                flexDirection: 'column',
                            } }, { children: [_jsxs(Box, __assign({ sx: { flex: 1 } }, { children: [_jsxs(Box, __assign({ className: 'candidate-card-text-header' }, { children: [_jsx(TPSvgIcon, { resourceName: 'small-vertical-line-icon', className: 'small-vertical-line-icon' }), _jsx(Typography, __assign({ className: 'candidate-update-card-title', id: "candidate-update-card-title-".concat(index), "data-testid": "candidate-update-card-title-".concat(index), fontSize: fontSizeDynamic(20) }, { children: action.title }))] })), _jsx(Typography, __assign({ className: 'candidate-update-info-text', id: "candidate-update-info-text-".concat(index), "data-testid": "candidate-update-info-text-".concat(index), fontSize: fontSizeDynamic(16) }, { children: action.description }))] })), _jsx(Box, __assign({ className: 'candidate-card-button' }, { children: action.actionButtonName ? (_jsx(TPButton, __assign({ color: 'primary', id: "candidate-update-card-btn-".concat(index), "data-testid": "candidate-update-card-btn-".concat(index), sx: {
                                            borderRadius: '0px',
                                            fontSize: fontSizeDynamic(16),
                                        }, onClick: function () {
                                            return navigationHandler(action.internalPageId, action.title, action.externalUrl);
                                        } }, { children: action.actionButtonName }))) : null }))] })) }), index));
                })) : (_jsx(Grid, __assign({ container: true }, { children: _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ sx: { textAlign: 'center', fontSize: fontSizeDynamic(16) }, className: 'no-candidate-update-info', id: 'no-candidate-update-info', "data-testid": 'no-candidate-update-info' }, { children: "No Call of Actions available." })) })) }))) }))] })));
};
export default CandidateUpdate;
